import { apiRequest } from "src/async/apiUtils";
import { baseUrl } from "src/config/host";

let base64String = "";

export const convertToBase64 = (file, setImage) => {
  if (file instanceof File && file.name) {
    const reader = new FileReader();

    reader.onload = () => {
      base64String = reader.result;
      const imageBase64Stringsep = base64String;
      setImage(imageBase64Stringsep);
    };

    reader.readAsDataURL(file);
  }
};

export const getS3ImageDetails = async (key) => {
  try {
    const { data: resData } = await apiRequest(
      "GET",
      baseUrl + `${key}`,
      {},
      {
        useCustomUrl: true,
        responseType: "blob",
      },
    );

    return {
      status: 200,
      data: URL.createObjectURL(resData),
    };
  } catch (error) {
    return { status: 400, message: error.message };
  }
};
