import axios from "axios";
import { apiUrl } from "src/config/host";

export const logoutRedirect = () => {
  /* fetch(`${apiUrl}/logout?RelayState=front`, {
    method: 'GET',
    headers: { 
      "Content-Type": "application/json", 
      Authorization: localStorage.getItem('accessToken'), 
    },
  }).then(res => {

    if (res.redirected) {
      window.location.href = res.url
    }
  })
    .catch(err => {
       toast.error(err.message);
    }) */

  window.location.href = `${apiUrl}/logout?RelayState=${window.location.origin}`;
};

const refreshTokenVerify = async () => {
  const refreshTokenRes = await fetch(`${apiUrl}/portal/refresh-token/verify`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
    }),
    body: JSON.stringify({}),
  });

  let refreshTokenData = {};

  if (refreshTokenRes.status === 200) {
    refreshTokenData = await refreshTokenRes.json();
    localStorage.setItem("email", refreshTokenData?.email);
    localStorage.setItem("accessToken", refreshTokenData.access_token);
    localStorage.setItem("refreshToken", refreshTokenData.refresh_token);
  }

  return { refreshTokenRes, refreshTokenData };
};

export const fetchData = async (method, host, body, signal = null, customHeaders = {}, contentType = "application/json") => {
  let finalBody = body;

  const token = localStorage.getItem("accessToken") || body?.token || customHeaders?.Authorization || null;

  if (contentType === "application/json") {
    if (!Array.isArray(body)) {
      finalBody = { ...body, token };
    }
    let signalParam = signal ? { signal } : {};
    const res = await fetch(host, {
      method,
      headers: new Headers({
        "Content-Type": contentType,
        Accept: "application/json",
        Authorization: `Bearer ${finalBody.token}`,
        ...customHeaders,
      }),
      body: method !== "GET" && body ? (contentType.includes("form-data") ? body : JSON.stringify(finalBody)) : null,
      ...signalParam,
    });

    if (res.status === 401) {
      const resData = await res.json();

      if (resData?.invalid) {
        logoutRedirect();
        return;
      }

      if (!resData?.expired) {
        return res;
      }

      try {
        const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify();
        if (refreshTokenRes.status !== 200) return res;

        return await fetch(host, {
          method,
          headers: new Headers({
            "Content-Type": contentType,
            Accept: "application/json",
            Authorization: `Bearer ${refreshTokenData.refresh_token}`,
            ...customHeaders,
          }),
          body: method !== "GET" && body ? (contentType.includes("form-data") ? body : JSON.stringify(finalBody)) : null,
          ...signalParam,
        });
      } catch (err) {
        return res;
      }
    }

    return res;
  } else {
    const request = await axios.request({
      url: host,
      method,
      data: finalBody,
      headers: {
        ...customHeaders,
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    if (request.status === 401) {
      if (request.data?.invalid) {
        logoutRedirect();
        return;
      }

      if (!request.data?.expired) {
        return request;
      }

      try {
        const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify();

        if (refreshTokenRes.status !== 200) {
          return request;
        }

        return await axios.request({
          url: host,
          method,
          data: finalBody,
          headers: {
            ...customHeaders,
            "Content-Type": contentType,
            Authorization: `Bearer ${refreshTokenData.refresh_token}`,
            Accept: "application/json",
          },
        });
      } catch (err) {
        return request;
      }
    }

    return request;
  }
};
