import React, { useEffect } from "react";
import BasicRouter from "src/components/embedTypes/basic/BasicRouter";
import DomoRouter from "src/components/embedTypes/domo/DomoRouter";
import MetabaseRouter from "./metabase/MetabaseRouter";
import QuickSightRouter from "./quicksight/QuickSightRouter";

export default function ContentTypesRouter({ content, menuType, workspace, site, setError, setLoading }) {
  useEffect(() => {
    if (!content?.config?.provider) {
      setError("Provider type does not exist.");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.config?.provider]);

  return (
    <>
      {content?.config?.provider === "BASIC" && (
        <BasicRouter
          content={content?.data}
          config={content?.config}
          setError={setError}
        />
      )}
      {content?.config?.provider === "DOMO" && (
        <DomoRouter
          content={content?.data}
          config={content?.config}
          setError={setError}
        />
      )}
      {content?.config?.provider === "QUICKSIGHT" && (
        <QuickSightRouter
          content={content?.data}
          config={content?.config}
          setError={setError}
        />
      )}
      {content?.config?.provider === "METABASE" && (
        <MetabaseRouter
          content={content?.data}
          config={content?.config}
          setError={setError}
        />
      )}
    </>
  );
}
