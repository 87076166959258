import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import Image from "src/components/elements/Image";
import { SiteContext } from "src/context/site";
import Logo from "src/assets/images/logo.png";

const AuthDataLayout = ({ title = "", subTitle = "", children, LinkUpComponent = () => <></> }) => {
  const { site } = useContext(SiteContext);

  const SiteImage = useCallback(
    () => (
      <Link
        to={"/"}
        className="block h-full w-full py-0.5">
        {site?._id && (
          <Image
            src={site?.image_logo ? `${site.image_logo}?${new Date().getTime()}` : Logo}
            alt={"Logo"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-contain"}
            hideImageOnError
          />
        )}
      </Link>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [site?._id],
  );

  return (
    <div className="flex min-h-full flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">{title}</h2>
        <div className="text-regular mt-2 text-center font-medium text-gray-600">{subTitle}</div>
      </div>
      <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="min-h-[183px] overflow-hidden rounded-lg bg-white shadow-md">
          <div className="bg-headerColor p-2">
            <div className="mx-auto h-20 w-auto px-3 max-w-[400px]">
              <SiteImage />
            </div>
          </div>
          <div className="px-4 py-8 sm:px-10">
            <div id="msg"></div>
            {children}
          </div>
        </div>
        <LinkUpComponent />
      </div>
    </div>
  );
};

export default AuthDataLayout;
