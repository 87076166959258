import React from "react";
import { classNames } from "src/helpers/classNames";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";

export default function ContentLoading({ menuType, nested }) {
  return (
    <div
      className={classNames(
        "flex items-center justify-center",
        menuType === "vertical" ? "" : "", // for vertical h-full
        nested ? "h-full" : "",
      )}>
      <div className="flex h-full w-full items-center justify-center">
        <Preloader />
      </div>
    </div>
  );
}
