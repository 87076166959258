import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "src/components/shared/ScrollToTop";
import AppRoutes from "src/routes/AppRoutes";
import "src/globals.css";

const Layout = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-backgroundColor flex flex-col">
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={900}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
};

export default Layout;
