import React, { useEffect, useState } from "react";

const MenuItemTextHighlighter = ({ text, highlight }) => {
  const [searchDesign, setSearchDesign] = useState("");

  useEffect(() => {
    if (highlight && text) {
      const parts = text.split(new RegExp(`(${highlight})`, "gi"));

      let highlightDesign = (
        <>
          {parts.map((part, index) => {
            return <React.Fragment key={`${text}_${index}_${highlight}`}>{part.toLowerCase() === highlight.toLowerCase() ? <span className="bg-yellow-400 bg-opacity-60">{part}</span> : part}</React.Fragment>;
          })}
        </>
      );
      setSearchDesign(highlightDesign);
    } else setSearchDesign(text);
  }, [text, highlight]);

  return <>{searchDesign}</>;
};

export default MenuItemTextHighlighter;
