import { createContext } from "react";

export const WorkspaceContext = createContext({
  loading: true,
  setLoading: () => {},
  list: [],
  setList: () => {},
  details: {},
  setDetails: () => {},
  menuType: "vertical",
  params: { workspace_id: null, group_id: null, page_id: null },
});
