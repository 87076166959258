import { useEffect, useState } from "react";

const useIframeResize = ({ workspaceDetails, site }) => {
  const [maxWidth, setMaxWidth] = useState("100%");
  const [disabledIframeExpansion, setDisabledIframeExpansion] = useState(false);

  useEffect(() => {
    const handleResizeMessage = (event) => {
      const data = event.data;
      if (data && data.method === "notifyFrameSize") {
        // Adjust the iframe height
        const iframe = document.getElementById("iframe");
        if (iframe) {
          iframe.height = `${data.params.height}px`;
        }
      }
    };

    if (maxWidth !== "100%" && !disabledIframeExpansion && workspaceDetails?.layout_type === "LEFT_NAVIGATION") {
      window.addEventListener("message", handleResizeMessage);
    }

    return () => {
      if (maxWidth !== "100%" && !disabledIframeExpansion && workspaceDetails?.layout_type === "LEFT_NAVIGATION") {
        window.removeEventListener("message", handleResizeMessage);
      }
    };
  }, [maxWidth, disabledIframeExpansion, workspaceDetails?.layout_type]);

  useEffect(() => {
    if (workspaceDetails?.use_global_settings) {
      setMaxWidth(site?.max_width || workspaceDetails?.max_width || "100%");
    } else {
      setMaxWidth(workspaceDetails?.max_width || "100%");
    }
  }, [workspaceDetails?.use_global_settings, workspaceDetails?.max_width, site?.max_width]);

  useEffect(() => {
    setDisabledIframeExpansion(workspaceDetails?.disabled_iframe_expansion || false);
  }, [workspaceDetails?.disabled_iframe_expansion]);

  return { maxWidth };
};

export default useIframeResize;
