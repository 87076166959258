import React, { useState } from "react";
import { classNames } from "src/helpers/classNames";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const Search = ({ value = "", onChange = () => {}, onKeyDown = () => {}, placeholder, divClasses, inputClasses, iconClasses, iconPosition = "left", icon = MagnifyingGlassIcon }) => {
  const [isFocus, setFocus] = useState(false);
  return (
    <>
      <div className={classNames("relative flex w-full overflow-hidden rounded border bg-white", divClasses, isFocus ? "border-highlightColor" : "border-gray-300")}>
        {iconPosition === "left" && (
          <div className={classNames("flex h-9 w-9 min-w-[36px] items-center justify-center bg-white", iconClasses, isFocus ? "text-highlightColor" : "text-gray-400")}>
            <MagnifyingGlassIcon className="h-5 w-5" />
          </div>
        )}
        <input
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type="text"
          placeholder={placeholder}
          className={classNames("h-9 w-full !border-0 bg-white p-0 pr-3 text-sm text-slate-600 !ring-0", inputClasses)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {iconPosition === "right" && (
          <div className={classNames("flex h-9 w-9 min-w-[36px] items-center justify-center bg-transparent text-slate-400", iconClasses)}>
            <MagnifyingGlassIcon className="h-4 w-4" />
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
