import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { outerPages } from "src/config/host";
import { AuthContext } from "src/context/auth";
import { SiteContext } from "src/context/site";
import Layout from "src/layouts/Layout";
import { apiRequest } from "./async/apiUtils";
import { setBrowserStyleProperties } from "./helpers/setBrowserStyleProperties";

function App() {
  const [siteLoading, setSiteLoading] = useState(true);
  const [site, setSite] = useState({});
  const [user, setUser] = useState(null);

  const loadSiteSettings = useCallback(() => {
    return (async () => {
      setSiteLoading(true);

      try {
        const url = "site-settings/unprotected";
        const { data: resData } = await apiRequest("GET", `/${url}`);

        const data = resData.data;

        setSite(data);

        return data;
      } catch (err) {
        toast.error(err.message);
      } finally {
        setSiteLoading(false);
      }
    })();
  }, []);

  const isOuterPage = outerPages.includes(window.location.pathname) || window.location.pathname.includes("/token/") || window.location.pathname.includes("/forgot-password/");

  useEffect(() => {
    if (isOuterPage) {
      setBrowserStyleProperties(document, site);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id, isOuterPage]);

  useEffect(() => {
    loadSiteSettings();
  }, [loadSiteSettings]);

  return (
    <SiteContext.Provider
      value={{
        loading: siteLoading,
        site,
        setSite,
        reloadSettings: loadSiteSettings,
      }}>
      <AuthContext.Provider value={{ user, setUser }}>
        <Layout />
      </AuthContext.Provider>
    </SiteContext.Provider>
  );
}

export default App;
