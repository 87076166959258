import { useEffect, useRef } from "react";
import { getMinuteDifference } from "src/helpers";

const LogoutTimer = ({ site, me, onLogout }) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    const newTimer = setTimeout(
      () => {
        onLogout();
      },
      site?.user_inactivity_logout_timeframe * 60 * 1000,
    );
    timerRef.current = newTimer;
  };

  const handleActivity = () => {
    resetTimer();
  };

  // Manage user logout on browser shutdown
  useEffect(() => {
    const handleUserInactivityVerification = (e) => {
      if (me?._id && site.user_inactivity_logout_enabled && site.user_inactivity_logout_timeframe) {
        localStorage.setItem("userLastActiveTime", new Date().toISOString());
      } else {
        localStorage.removeItem("userLastActiveTime");
      }
    };

    const verifyLastUserActivity = () => {
      // Checking if logged in and inactivity timeframe is available
      if (me?._id && site.user_inactivity_logout_enabled && site.user_inactivity_logout_timeframe && localStorage.getItem("userLastActiveTime")) {
        const minuteDifference = getMinuteDifference(new Date(), new Date(localStorage.getItem("userLastActiveTime"))) >= site.user_inactivity_logout_timeframe;

        // if minutes are passed, log the user out
        if (minuteDifference >= site.user_inactivity_logout_timeframe) {
          onLogout();
        } else {
          // if minutes are not passed, remove the storage value
          localStorage.removeItem("userLastActiveTime");
        }
      } else if (site._id && !site.user_inactivity_logout_enabled) {
        // if inactivity settings is unavailable, the remove the storage value
        localStorage.removeItem("userLastActiveTime");
      }
    };

    verifyLastUserActivity();

    window.addEventListener("beforeunload", handleUserInactivityVerification);

    return () => {
      window.removeEventListener("beforeunload", handleUserInactivityVerification);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site.user_inactivity_logout_enabled, site.user_inactivity_logout_timeframe, me]);

  useEffect(() => {
    // Initial setup on component mount
    resetTimer();

    // Attach event listeners to reset the timer on user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearTimeout(timerRef.current); // Clear the timer on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLogout, site?.user_inactivity_logout_timeframe]);

  return null;
};

export default LogoutTimer;
