import { CheckIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const Radio = ({ radioName, radioInputClass, isChecked, radioLabel, radioLableClass, onChange = () => {}, ...props }) => {
  return (
    <>
      <div className="relative flex items-center overflow-hidden">
        <input
          type="radio"
          name={radioName}
          className={classNames("peer absolute left-0 top-0 z-10 h-5 w-5 cursor-pointer opacity-0", radioInputClass)}
          checked={isChecked}
          onChange={onChange}
        />
        <div className="flex h-5 min-h-[20px] w-5 min-w-[20px] flex-shrink-0 items-center justify-center rounded-full border border-slate-300 bg-white text-[10px] text-black/0 peer-checked:border-highlightColor peer-checked:bg-highlightColor peer-checked:text-white">
          <CheckIcon className="h-4 w-4" />
        </div>
        {radioLabel && <div className={classNames("ml-3 w-full text-sm text-slate-500", radioLableClass)}>{radioLabel}</div>}
      </div>
    </>
  );
};

export default Radio;
