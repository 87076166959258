import { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import AuthLayout from "src/layouts/AuthLayout";
import { AuthContext } from "src/context/auth";

const FreeAuthRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useContext(AuthContext);

  const clickWorkspace = async () => {
    try {
      const { data: resData } = await apiRequest("GET", `/portal/users/workspaces/page`);

      if (resData.status === 200) {
        navigate(resData.link);
      } else {
        navigate("/permission-denied");
      }
    } catch (err) {
      navigate("/permission-denied");
    }
  };

  const redirectToWorkspace = async () => {
    try {
      const { data: resultData } = await apiRequest("post", "/portal/users/self/details", {
        body: {
          token: localStorage.getItem("accessToken"),
          access_token: localStorage.getItem("accessToken"),
        },
      });

      if (resultData.status !== 200) {
        logoutRedirect();
      }

      setUser(resultData.data);

      clickWorkspace();
    } catch (err) {
      navigate("/permission-denied");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") && location.pathname !== "/sso-login") {
      redirectToWorkspace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (localStorage.getItem("accessToken") && location.pathname !== "/sso-login") {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center pt-16">
        <Preloader />
      </div>
    );
  }

  return <AuthLayout />;
};

export default FreeAuthRoute;
