import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";

const AuthenticationVerify = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const verifyToken = async () => {
    try {
      const { data } = await apiRequest("get", "/portal/authentication/verify", {}, { headers: { Authorization: `Bearer ${token}` } });
      localStorage.setItem("accessToken", data.access_token);
      localStorage.setItem("refreshToken", data.refresh_token);
      navigate(data.link);
    } catch (err) {
      navigate("/");
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="flex h-full items-center justify-center">
      <Preloader />
    </div>
  );
};

export default AuthenticationVerify;
