import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/form/Button";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { AuthContext } from "src/context/auth";
import { clearCookies } from "src/helpers";

const Logout = ({ sso = false }) => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    localStorage.clear();
    clearCookies();
    setUser(null);
    if (!sso) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sso]);

  if (!sso) {
    return (
      <div className="flex items-center justify-center">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="flex min-h-screen w-full flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">You have been logged out</h2>
        <div className="text-regular mt-1 text-center text-gray-600">Get access to all your data in one perfectly organized place by logging back in.</div>
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="px-4 py-4 sm:px-10">
          <Button
            buttonClasses={"w-full"}
            buttonLabel={"Login"}
            buttonFunction={() => navigate(`/`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Logout;
