import React from "react";
import Checkbox from "src/components/form/Checkbox";
import Radio from "src/components/form/Radio";

const FormChoiceGrid = ({ content = null, answer = [], onHandleChange = () => {} }) => {
  const onHandleRadioChange = (row_id, option_id) => {
    const index = answer?.findIndex((ans) => ans?.row_id?.toString() === row_id?.toString());
    if (index !== -1) {
      answer[index].option_id = option_id;
    } else {
      answer = [...answer, { row_id, option_id }];
    }
    onHandleChange(content?._id, "value", answer);
  };
  const onHandleCheckboxChange = (e, row_id, option_id) => {
    const index = answer?.findIndex((ans) => ans?.row_id?.toString() === row_id?.toString());
    if (index !== -1) {
      answer[index].option_id = e.target.checked ? [...(answer[index].option_id || []), option_id] : [...(answer[index].option_id || [])?.filter((val) => val?.toString() !== option_id?.toString())];
    } else {
      answer = [...answer, { row_id, option_id: [option_id] }];
    }
    onHandleChange(content?._id, "value", answer);
  };
  return (
    <div className="relative space-y-4">
      <div className="text-base font-semibold text-gray-700">
        {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
      </div>
      <div className="relative flex w-full">
        <div className="w-full max-w-[calc(100vw-32rem)] overflow-auto">
          <div className="relative flex">
            <div className="sticky left-0 z-[1] w-24 min-w-24 flex-grow bg-white px-2 py-4"></div>
            {content?.options?.map((option) => (
              <div
                key={option?._id}
                className="flex w-full min-w-24 flex-grow items-center justify-center px-2 py-4 text-sm font-semibold text-gray-700">
                {option?.name}
              </div>
            ))}
          </div>
          {content?.rows?.map((row) => (
            <div
              key={row?._id}
              className="relative flex">
              <div className="sticky left-0 z-[1] flex w-24 min-w-24 flex-grow items-center bg-white px-2 py-4 text-sm font-semibold text-gray-700">{row?.name}</div>
              {content?.options?.map((option) => (
                <div
                  key={option?._id}
                  className="flex w-full min-w-24 flex-grow items-center justify-center px-2 py-4">
                  {content?.question_type === "radio_grid" ? (
                    <Radio
                      radioName={`radio-${row?._id}`}
                      isChecked={answer?.find((ans) => ans?.row_id?.toString() === row?._id?.toString())?.option_id?.toString() === option?._id?.toString() ? true : false}
                      onChange={(e) => onHandleRadioChange(row?._id, option?._id)}
                    />
                  ) : (
                    <Checkbox
                      isChecked={answer?.find((ans) => ans?.row_id?.toString() === row?._id?.toString())?.option_id?.includes(option?._id?.toString()) ? true : false}
                      key={option?._id}
                      checkboxBoxClass={"h-5 w-5 flex-shrink-0"}
                      // checkboxLabel={!option?.other ? option?.name : "Other"}
                      onChange={(e) => onHandleCheckboxChange(e, row?._id, option?._id)}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormChoiceGrid;
