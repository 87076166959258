import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import NoImage from "src/assets/images/user-default.png";
import { classNames } from "src/helpers/classNames";
import { getS3ImageDetails } from "src/helpers/image";

const S3Image = ({ src = "", width, height, alt, effect, classes, userNoImage = false }) => {
  const [s3Image, setS3Image] = useState({
    loading: true,
    data: null,
  });

  const [invalidImage, setInvalidImage] = useState(false);

  useEffect(() => {
    if (src && !src.includes("data:image/")) {
      const loadS3Image = async () => {
        setS3Image((data) => ({ ...data, loading: true }));
        const res = await getS3ImageDetails(src);
        if (res.status === 200) {
          setS3Image({ loading: false, data: res.data });
        } else {
          setS3Image((data) => ({ ...data, loading: false }));
        }
      };

      loadS3Image();
    }
  }, [src]);

  return (
    <>
      {s3Image.loading || !src ? (
        userNoImage ? (
          <LazyLoadImage
            src={NoImage}
            width={width}
            height={height}
            alt={alt}
            effect={effect}
            className={classNames("h-full w-full", classes)}
            onError={() => setInvalidImage(true)}
          />
        ) : (
          <></>
        )
      ) : (
        <LazyLoadImage
          src={s3Image.data && !invalidImage && src ? (s3Image.data instanceof File ? URL.createObjectURL(s3Image.data) : s3Image.data || src) : NoImage}
          width={width}
          height={height}
          alt={alt}
          effect={effect}
          className={classNames("h-full w-full", classes)}
          onError={() => setInvalidImage(true)}
        />
      )}
    </>
  );
};

export default S3Image;
