import LeftNavigationMenu from "src/components/navigation/leftNavigation/LeftNavigationMenu";
import TopNavigationMenu from "src/components/navigation/topNavigation/TopNavigationMenu";

export default function NavigationRouter({ menuType, isMenu, setIsMenu, menus, setMenus, simulatedMenus, setSimulatedMenus, keyword, setKeyword }) {
  return (
    <>
      {menus.length > 1 && (
        <>
          {menuType === "vertical" && (
            <LeftNavigationMenu
              isMenu={isMenu}
              setIsMenu={setIsMenu}
              menus={menus}
              setMenus={setMenus}
              simulatedMenus={simulatedMenus}
              setSimulatedMenus={setSimulatedMenus}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          )}
          {menuType === "horizontal" && (
            <TopNavigationMenu
              isMenu={isMenu}
              setIsMenu={setIsMenu}
              menus={menus}
              setMenus={setMenus}
              simulatedMenus={simulatedMenus}
              setSimulatedMenus={setSimulatedMenus}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          )}
        </>
      )}
    </>
  );
}
