import { classNames } from "src/helpers/classNames";
import PreloaderIcon from "src/components/shared/LoadingAnimations/PreloaderIcon";

const Preloader = ({ text = "Loading...", className = "", size = "lg", circleDimension = "14" }) => {
  return (
    <div className={classNames("flex items-center justify-center gap-x-4 rounded px-8 py-5", className)}>
      <PreloaderIcon
        height={`h-${circleDimension}`}
        width={`w-${circleDimension}`}
      />
      <p className={`m-0 text-${size} text-gray-400`}>{text}</p>
    </div>
  );
};

export default Preloader;
