import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React from "react";

const ExternalLinkEmbed = ({ href = "", target = true }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <a
        href={href}
        target={target ? "_blank" : "_self"}
        rel="noreferrer"
        className="flex h-10 items-center justify-between rounded px-3 text-gray-600 underline transition-all">
        <div className="flex items-center gap-2 border border-slate-200 rounded-lg bg-white shadow px-4 py-3 hover:text-highlightColor hover:shadow-lg transition-all duration-200">
          <div className="text-base font-semibold">Click here to open this link</div>
          <div>
            <ArrowTopRightOnSquareIcon className="ml-2 h-5 w-5 stroke-2" />
          </div>
        </div>
      </a>
    </div>
  );
};

export default ExternalLinkEmbed;
